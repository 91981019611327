export const getTextCoordinates = (lat, lng) => {
  const latText = Math.abs(Math.round(lat * 10000) / 10000);
  const latNS = lat >= 0 ? ` N` : ` S`;
  const lngText = Math.abs(Math.round(lng * 10000) / 10000);
  const lngEW = lng <= 0 ? ` W` : ` E`;
  return `${latText}°${latNS} ${lngText}°${lngEW}`;
};

/**
 *
 * Default coords are Lat: 37.7648  Lng: -122.463
 *
 * legacy (saved design) & current live have different decimal
 * precision,  (some lat. are -122.463 and others are -122.4631)
 * so block range, not exact value
 */
export const hasDefaultCoordinates = (customProps) => {
  if (customProps && customProps.latitude === 37.7648 && customProps.longitude <= -122.463 && customProps.longitude > -122.464) {
    // add logging:
    if (customProps.isLocationSelected) {
      console.error(`Location Selected is true, but coordinates are default`, customProps);
    }

    return true;
  }

  // add logging:
  if (!customProps.isLocationSelected) {
    console.error(
      `Location Selected is false, but coordinates are not default (${customProps.latitude}, ${customProps.longitude})`,
      customProps,
    );
  }

  return false;
};
