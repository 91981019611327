import React from "react";
import env from "../config/environment";

const { ACTIVE_SITE } = env;
const isOrrery = ACTIVE_SITE === "theplanetsaligned";
const isNightSkyMaps = ACTIVE_SITE === "nightskymaps";

let TnsPrintPropsContext;
const tnsPrintPropsContext: React.Context<any> = (TnsPrintPropsContext = React.createContext({}));
const TnsPrintPropsConsumer = tnsPrintPropsContext.Consumer;
const TnsPrintPropsContextProvider = tnsPrintPropsContext.Provider;

let OrreryPropsContext;
const orreryPropsContext: React.Context<any> = (OrreryPropsContext = React.createContext({}));
const OrreryPropsConsumer = orreryPropsContext.Consumer;
const OrreryPropsContextProvider = orreryPropsContext.Provider;

// exports the config set context
// const PropsContext = isOrrery ? OrreryPropsContext : TnsPrintPropsContext;

// exports the config set context
let PropsContext = TnsPrintPropsContext;
if (isNightSkyMaps) {
  PropsContext = TnsPrintPropsContext;
} else if (isOrrery) {
  PropsContext = OrreryPropsContext;
}

export { PropsContext, TnsPrintPropsContextProvider, OrreryPropsContextProvider };
