/* eslint-disable no-console */
import axios from "axios";
import env from "../config/environment";
import { ICart, ICartItem, IShippingObject, IBillingObject, IDiscountObject } from "../interfaces";
import getCartWithoutImages from "../utils/cart";

const { TNS_PLATFORM_API, DEFAULT_PRODUCT_TYPE } = env;

const createOrder = async (
  email: string,
  cart: ICart,
  canMarket: boolean,
  firstName: string,
  lastName: string,
  giftNote: string,
  shipping: IShippingObject,
  billing: IBillingObject,
  shouldUseSameAddress: boolean,
  discount?: IDiscountObject,
  hostname?: string,
): Promise<{
  customerId: string;
  stripeEurCustomerId: string;
  stripeUsdCustomerId: string;
  orderId: string;
  isReturning: boolean;
  friendlyOrderId: string;
  updatedDiscount: IDiscountObject;
  cart: ICart;
}> => {
  const realEmail = email.trim().toLowerCase();

  // First, make sure we don't send the base64 images from the items.
  const cartWithoutImages = getCartWithoutImages(cart);
  const d = {
    email: realEmail,
    cart: cartWithoutImages,
    canMarket,
    productType: cart.productType || DEFAULT_PRODUCT_TYPE,
    firstName,
    lastName,
    giftNote,
    shipping,
    billing: shouldUseSameAddress ? shipping : billing,
    discount,
    hostname,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/create`, d);
    const {
      customerId,
      stripeEurCustomerId,
      stripeUsdCustomerId,
      orderId,
      isReturning,
      friendlyOrderId,
      updatedDiscount,
      cart: updatedCart,
    } = data;
    return {
      customerId,
      stripeEurCustomerId,
      stripeUsdCustomerId,
      orderId,
      isReturning,
      friendlyOrderId,
      updatedDiscount,
      cart: updatedCart,
    };
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const saveDesign = async (
  email: string,
  cart: ICart,
  canMarket: boolean,
  firstName: string,
  lastName: string,
  shipping: IShippingObject,
  billing: IBillingObject,
  hostname?: string,
): Promise<{
  customerId: string;
  stripeEurCustomerId: string;
  stripeUsdCustomerId: string;
  orderId: string;
  friendlyOrderId: string;
  cart: ICart;
}> => {
  // First, make sure we don't send the base64 images from the items.
  const cartWithoutImages = getCartWithoutImages(cart);
  const realEmail = email.toLowerCase();

  const d = {
    email: realEmail,
    cart: cartWithoutImages,
    canMarket,
    productType: cart.productType || DEFAULT_PRODUCT_TYPE,
    firstName,
    lastName,
    shipping,
    billing,
    hostname,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/saveDesign`, d);
    const { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId, friendlyOrderId, cart: updatedCart } = data;
    return { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId, friendlyOrderId, cart: updatedCart };
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const dropAHint = async (
  dropAHintFormData: string[],
  cart: ICart,
  canMarket: boolean,
  shipping: IShippingObject,
  billing: IBillingObject,
  hostname?: string,
): Promise<{
  customerId: string;
  stripeEurCustomerId: string;
  stripeUsdCustomerId: string;
  orderId: string;
  friendlyOrderId: string;
  cart: ICart;
}> => {
  // First, make sure we don't send the base64 images from the items.
  const cartWithoutImages = getCartWithoutImages(cart);
  const [recipientName, recipientEmail, senderName, senderEmail, productType] = dropAHintFormData;

  const d = {
    recipientName,
    recipientEmail,
    senderName,
    senderEmail,
    productType,
    cart: cartWithoutImages,
    canMarket,
    shipping,
    billing,
    hostname,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/dropAHint`, d);
    const { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId, friendlyOrderId, cart: updatedCart } = data;
    return { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId, friendlyOrderId, cart: updatedCart };
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const updateItemDesign = async (
  customerId: string,
  orderId: string,
  item: ICartItem,
  oldItem: ICartItem,
  userEmail?: string,
): Promise<any> => {
  const d = {
    customerId,
    orderId,
    item,
    userEmail,
    oldItem,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/updateItemDesign`, d);
    return data;
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const getCartWithPricing = async (
  cart: ICart,
  shipping: IShippingObject,
  orderId: string,
  includeShippingUpgrades: boolean,
  includeItemUpgrades: boolean,
): Promise<ICart> => {
  const cartWithoutImages = getCartWithoutImages(cart);
  const d = {
    cart: cartWithoutImages,
    shipping,
    orderId,
    includeShippingUpgrades,
    includeItemUpgrades,
  };

  const { data }: { data: any } = await axios.post(`${TNS_PLATFORM_API}/api/pricing/getCartWithPricing`, d);
  if (data.error) {
    throw Error(data.message);
  }

  // Put the images back:
  if (cart && cart.items && cart.items.length > 0 && data && data.items && data.items.length === cart.items.length) {
    for (let i = 0; i < cart.items.length; i += 1) {
      const { previewImageUrl } = cart.items[i];
      if (previewImageUrl !== undefined && previewImageUrl !== null && previewImageUrl !== "") {
        data.items[i].previewImageUrl = previewImageUrl;
      }
    }
  }

  return data;
};

const updateCart = async (
  orderId: string,
  cart: ICart,
  shipping: IShippingObject,
  billing: IBillingObject,
  shouldUseSameAddress: boolean,
): Promise<any> => {
  const cartWithoutImages = getCartWithoutImages(cart);
  const d = {
    orderId,
    cart: cartWithoutImages,
    shipping,
    billing: shouldUseSameAddress ? shipping : billing,
  };

  try {
    await axios.post(`${TNS_PLATFORM_API}/api/orders/updateCart`, d);
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const updateCustomer = async (email: string, canMarket: boolean, customerIdParam: string, orderIdParam: string): Promise<any> => {
  const realEmail = email.toLowerCase();

  const d = {
    email: realEmail,
    canMarket,
    customerId: customerIdParam,
    orderIdParam,
    DEFAULT_PRODUCT_TYPE,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/updateCustomer`, d);
    const { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId } = data;
    return { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId };
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const editOrder = async (
  orderIdParam: string,
  cart: ICart,
  shipping: IShippingObject,
  billing: IBillingObject,
  shouldUseSameAddress: boolean,
  giftNote: string,
  email?: string,
  discount?: IDiscountObject,
): Promise<{
  customerId: string;
  stripeEurCustomerId: string;
  stripeUsdCustomerId: string;
  orderId: string;
  isReturning: boolean;
  friendlyOrderId: string;
  updatedDiscount: IDiscountObject;
}> => {
  const cartWithoutImages = getCartWithoutImages(cart);

  const d = {
    orderId: orderIdParam,
    cart: cartWithoutImages,
    shipping,
    billing: shouldUseSameAddress ? shipping : billing,
    giftNote,
    email,
    discount,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/orders/edit`, d);
    const { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId, isReturning, friendlyOrderId, updatedDiscount } = data;
    return { customerId, stripeEurCustomerId, stripeUsdCustomerId, orderId, isReturning, friendlyOrderId, updatedDiscount };
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const getOrderForEdit = async (orderId: string): Promise<any> => {
  try {
    const { data } = await axios.get(`${TNS_PLATFORM_API}/api/orders/edit/${orderId}`);
    return data;
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const getOrderForDownload = async (orderId: string): Promise<any> => {
  try {
    const { data } = await axios.get(`${TNS_PLATFORM_API}/api/orders/download/${orderId}`);
    return data;
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const createGiftCard = async (
  balance: number,
  ownerEmail: string,
  recipientEmail: string,
  recipientName: string,
  giftCardMessage: string,
  countryCode: string,
  canSavePaymentDetails: boolean,
  sku: string,
  productType: string,
  productName: string,
  paymentMethodId?: string,
  paymentIntentId?: string,
  isPayPal?: boolean,
  payPalOrderId?: string,
): Promise<any> => {
  const realEmailA = ownerEmail.toLowerCase();
  const realEmailB = recipientEmail.toLowerCase();

  const d = {
    balance,
    ownerEmail: realEmailA,
    recipientEmail: realEmailB,
    recipientName,
    giftCardMessage,
    countryCode,
    canSavePaymentDetails,
    sku,
    paymentMethodId,
    paymentIntentId,
    productName,
    productType,
    isPayPal,
    payPalOrderId,
  };

  try {
    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/customer/createGiftCard`, d);

    return data;
  } catch (err) {
    console.error(err.response.data);
    throw Error(err.response.data.message);
  }
};

const getDeliveryTimingForCountry = async (countryCode: string, timezone?: string): Promise<any> => {
  try {
    let query = ``;
    let separator = `?`;
    if (countryCode || countryCode === "") {
      query += `${separator}countryCode=${countryCode}`;
      separator = "&";
    }
    if (timezone || timezone === "") {
      query += `${separator}timezone=${encodeURI(timezone)}`;
      separator = "&";
    }

    const { data } = await axios.get(`${TNS_PLATFORM_API}/api/orders/getDeliveryTimingForCountry${query}`);
    if (data) {
      return data;
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};

export default {
  // Order Management
  createOrder,
  saveDesign,
  dropAHint,
  updateItemDesign,
  updateCart,
  updateCustomer,
  getOrderForEdit,
  getOrderForDownload,
  editOrder,
  createGiftCard,
  getDeliveryTimingForCountry,
  //
  getCartWithPricing,
};
