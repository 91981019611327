import moment from "moment";
import configApi from "../../api/config";
import env from "../../config/environment";
import { ICartItem } from "../../interfaces";
import { getTextCoordinates } from "../coordinates";
// import { getShortNameforProductName } from "../utils";

const { APP_NAME, brandName, brandNameShort } = env;

const getGa4DateTimeString = (): string => {
  return moment().utc().format("DD/MM/YYYY - HH:mm:ss");
};

const getDateStringFromTimeObject = (time: any): string => {
  const newMonth = `0${time.month}`.slice(-2);
  const newDate = `0${time.date}`.slice(-2);
  const newHour = `0${time.hour}`.slice(-2);
  const newMinute = `0${time.minute}`.slice(-2);
  const constructDate = `${time.year}-${newMonth}-${newDate}T${newHour}:${newMinute}:00Z`;

  return moment.utc(constructDate).format("MMMM Do, YYYY");
};

const getTimeStringFromTimeObject = (time: any): string => {
  const newMonth = `0${time.month}`.slice(-2);
  const newDate = `0${time.date}`.slice(-2);
  const newHour = `0${time.hour}`.slice(-2);
  const newMinute = `0${time.minute}`.slice(-2);
  const constructDate = `${time.year}-${newMonth}-${newDate}T${newHour}:${newMinute}:00Z`;

  return moment.utc(constructDate).format("HH:mm");
};

const dataLayerPush = (data: any) => {
  // if (!window) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

const getThemeNameFromThemeId = (themeId: string): string => {
  let theme = "Midnight Blue";
  try {
    switch (themeId.toLowerCase()) {
      case "aquamarine": {
        theme = "Aquamarine";
        break;
      }
      case "black": {
        theme = "Black";
        break;
      }
      case "white": {
        theme = "White";
        break;
      }
      case "grayblue": {
        theme = "Gray Blue";
        break;
      }
      case "midnightblue":
      default: {
        theme = "Midnight Blue";
        break;
      }
    }
  } catch (error) {
    // ignore
  }

  return theme;
};

const getProductName = (item: ICartItem) => {
  let productName = "Print Only";
  if (item.isPendant) {
    productName = "Pendant";
  } else if (item.isDigital) {
    productName = "Digital Download";
  } else if (item.isCanvas) {
    productName = "Canvas";
  } else if (item.isFramed) {
    productName = "Framed";
  }

  return productName;
};

// ///////////////////////////////////////////////////////
// PAGE VIEW
//
// Home Page:
// dataLayerPush({
//   'date_time': '19/09/2020 - 17:31:00',
//   'page_category':'Homepage'
// });

const logPageViewCreate = (): void => {
  dataLayerPush({
    event: "start",
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// ///////////////////////////////////////////////////////
// USER REGISTRATION
//
const logUserRegistration = (customerId: string, store: string): void => {
  dataLayerPush({
    event: "user_registration",
    user_id: customerId,
    store_used: store,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// ///////////////////////////////////////////////////////
// THEME OPTIONS DEFAULTS
//
const logEventDefaultTheme = (themeId: string): void => {
  const theme = getThemeNameFromThemeId(themeId);
  dataLayerPush({
    event: "background_default",
    background_option: theme,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultThemeOptionOnWhite = (value: boolean): void => {
  dataLayerPush({
    event: "theme_default_on_white",
    on_white: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultThemeOptionConstellations = (value: boolean): void => {
  dataLayerPush({
    event: "theme_default_constellations",
    constellations: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultThemeOptionGrid = (value: boolean): void => {
  dataLayerPush({
    event: "theme_default_grid",
    grid: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultThemeOptionMoon = (value: boolean): void => {
  dataLayerPush({
    event: "theme_default_moon",
    moon: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultDesignLayout = (layout: string): void => {
  dataLayerPush({
    event: "layout_default",
    layout,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultDesignFormat = (format: string): void => {
  dataLayerPush({
    event: "format_default",
    format,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDefaultDesignSize = (size: string): void => {
  dataLayerPush({
    event: "size_default",
    size,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// ///////////////////////////////////////////////////////
// DESIGN PAGE OPTIONS SELECTIONS
//
const logEventTheme = (themeId: string): void => {
  const theme = getThemeNameFromThemeId(themeId);
  dataLayerPush({
    event: "background_selected",
    background_option: theme,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventThemeOptionOnWhite = (value: boolean): void => {
  dataLayerPush({
    event: "theme_selected_on_white",
    on_white: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventThemeOptionConstallations = (value: boolean): void => {
  dataLayerPush({
    event: "theme_selected_constellations",
    constellations: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventThemeOptionGrid = (value: boolean): void => {
  dataLayerPush({
    event: "theme_selected_grid",
    grid: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventThemeOptionMoon = (value: boolean): void => {
  dataLayerPush({
    event: "theme_selected_moon",
    moon: value ? `true` : `false`,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user includes the location
const logEventDesignLocation = (location: string): void => {
  dataLayerPush({
    event: "location",
    location,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user choses a date
const logEventDesignDate = (date: string): void => {
  dataLayerPush({
    event: "date",
    date,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user includes texts
const logEventDesignText = (text: string): void => {
  dataLayerPush({
    event: "text",
    text_type: text,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user includes texts
const logEventDesignCoordinatesText = (latitude: number, longitude: number): void => {
  const textCoordinates = getTextCoordinates(latitude, longitude);
  dataLayerPush({
    event: "coordinates",
    coordinates: textCoordinates,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user includes texts
const logEventDesignTime = (time: string): void => {
  dataLayerPush({
    event: "show_time",
    show_time: time,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const logEventDesignLayout = (layout: string): void => {
  // When the user selects a different option for layout
  dataLayerPush({
    event: "layout_selected",
    layout,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user selects a different option for format
const logEventDesignFormat = (format: string): void => {
  dataLayerPush({
    event: "format_selected",
    format,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// When the user selects a different size
const logEventDesignSize = (size: string): void => {
  dataLayerPush({
    event: "size_selected",
    size,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

// ///////////////////////////////////////////////////////
// When the user saves the design for later
const logEventDesignSaveDesign = (customerId: string): void => {
  dataLayerPush({
    event: "save_design",
    status: true,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
    user_id: customerId,
  });
};

// ///////////////////////////////////////////////////////
// When the user drops a hint
const logEventDesignDropAHint = (customerId: string): void => {
  dataLayerPush({
    event: "drop_a_hint",
    status: true,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
    user_id: customerId,
  });
};

/// ////////////////////////////////////////////////////
// When the user adds a gift note
const logEventGiftCard = (customerId: string, productName: string | null): void => {
  dataLayerPush({
    event: "add_gift_note",
    product: productName,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
    user_id: customerId,
  });
};

const upsellPendantPrintClicked = (productName: string): void => {
  dataLayerPush({
    event: "upsell_clicked",
    product: productName,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
  });
};

const getProductFromItems = (items: ICartItem[]) => {
  try {
    if (!items || items.length < 1) {
      return "";
    }

    if (items.every((x) => !x.isPendant && !x.isFramed && !x.isCanvas && !x.isDigital)) {
      return "Print";
    }
    if (items.every((x) => x.isPendant)) {
      return "Pendant";
    }
    if (items.every((x) => x.isFramed)) {
      return "Framed Print";
    }
    if (items.every((x) => x.isCanvas)) {
      return "Canvas";
    }
    if (items.every((x) => x.isDigital)) {
      return "Digital";
    }

    //
    // Mixed:
    //
    const mixedProductName: string[] = [];
    if (items.some((x) => !x.isPendant && !x.isFramed && !x.isCanvas && !x.isDigital)) {
      mixedProductName.push("Print");
    }
    if (items.some((x) => x.isPendant)) {
      mixedProductName.push("Pendant");
    }
    if (items.some((x) => x.isFramed)) {
      mixedProductName.push("Framed");
    }
    if (items.some((x) => x.isCanvas)) {
      mixedProductName.push("Canvas");
    }
    if (items.some((x) => x.isDigital)) {
      mixedProductName.push("Digital");
    }

    // return mixed name "Print+Pendant", "Print+Canvas", "Canvas+Pendant", etc
    if (mixedProductName.length > 0) {
      return mixedProductName.join("+");
    }
  } catch (error) {
    console.error(`Could not get product for tracking from items`, error);
  }

  return "";
};

/// ////////////////////////////////////////////////////
// When the user adds a discount
const logEventCheckoutDiscount = (customerId: string, discountCode: string, amountInCents: number, items: ICartItem[]): void => {
  dataLayerPush({
    event: "discount",
    product: getProductFromItems(items),
    discount: discountCode,
    discount_value: amountInCents > 0 ? amountInCents / 100 : 0,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
    user_id: customerId,
  });
};

/// ////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////
/// ////////////////////////////////////////////////////

// When the user chooses a different shipping method
const logEventCheckoutShipping = (customerId: string, express: boolean): void => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "checkoutOption",
    ecommerce: {
      checkout_option: {
        actionField: { step: 2, option: `${express ? `Express` : `Standard`} Shipping` },
      },
    },
    user_id: customerId,
    date_time: getGa4DateTimeString(),
    page_category: "Checkout",
  });
};

// When the user chooses a different payment method
const logEventCheckoutPayment = (customerId: string, option: string): void => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "checkoutOption",
    ecommerce: {
      checkout_option: {
        actionField: { step: 3, option },
      },
    },
    user_id: customerId,
    date_time: getGa4DateTimeString(),
    page_category: "Checkout",
  });
};

// ///////////////////////////////////////////////////////
// When the user edits the item in the cart
const logEventEditOrder = (item: ICartItem, customerId?: string): void => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "edit_order",
    product: getProductName(item),
    status: true,
    date_time: getGa4DateTimeString(),
    page_category: "Product",
    ...(customerId && customerId !== "" ? { user_id: customerId } : null),
  });
};

// ///////////////////////////////////////////////////////
// When the user buys a gift card
const logEventGiftCardPurchase = (productDescription: string, countryCode: string, customerId: string, amountInCents: number): void => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "gift_card",
    date_time: getGa4DateTimeString(),
    page_category: "Product",
    product: productDescription,
    country: countryCode,
    user_id: customerId,
    price: amountInCents > 0 ? amountInCents / 100 : 0,
  });
};

/// ////////////////////////////////////////////////////
// When the user id is generated
// "dataLayer.push({
//   'event':'user_registration',
//   'user_id':XXXXXX,
//   'date_time': '19/09/2020 - 17:31:00',
//   'page_category':'Product',
//   'store_used':{{store}}
// });"

/// ////////////////////////////////////////////////////
// When the user register for newsletter
// "dataLayer.push({
//   'event':'newsletter',
//   'user_id':XXXXXX,
//   'date_time': '19/09/2020 - 17:31:00',
//   'page_category':'Product'
// });"

/// ////////////////////////////////////////////////////
// When the user buys a gift card
//
// dataLayer.push({
//   'event':'gift_card',
//   'user_id':XXXXXX,
//   'date_time': '19/09/2020 - 17:31:00',
//   'page_category':'Product',
//   'country':{{country}},
//   'product':{{product description}},
//   'price':{{price}}
// });

/// ////////////////////////////////////////////////////
//
//
const getCartProduct = async (item: ICartItem, productPrice: number) => {
  // console.log(`getCartProduct: ${JSON.stringify(item, null, 4)}`);
  // console.log(`productPrice: ${JSON.stringify(productPrice, null, 4)}`);
  // if (item.price === undefined || item.price?.grossPrice === null) {
  //   console.error(`Error recording purchase action in GA`);
  //   return null;
  // }

  const variant = await configApi.getTnsSku({
    ...item,
    previewImageUrl: undefined,
  });

  // factor in shipping and discounts somehow?
  const itemPrice = item.price && item.price.productPrice && item.price.productPrice > 0 ? item.price.productPrice / 100 : 0;
  const itemPricing = productPrice || itemPrice || null;

  const productName = getProductName(item);

  let itemCategory = "Print";
  let sizeId: string | null = null;
  let textCoordinates: string | null = null;
  let dateString: string | null = null;
  let timeString: string | null = null;

  if (item && item.design && item.design.customProps) {
    // Get Category
    if (item.design.customProps.orientation === "portrait") {
      itemCategory = "Portrait";
    } else if (item.design.customProps.orientation === "landscape") {
      itemCategory = "Landscape";
    } else if (item.design.customProps.orientation === "square") {
      itemCategory = "Square";
    }

    // Coords
    if (item.design.customProps.latitude && item.design.customProps.longitude) {
      textCoordinates = getTextCoordinates(item.design.customProps.latitude, item.design.customProps.longitude);
    }

    if (item.design.customProps.sizeId) {
      sizeId = item.design.customProps.sizeId;
    }

    if (item.design.customProps.time && item.design.customProps.time.date && item.design.customProps.time.hour) {
      // we have a good time object
      dateString = getDateStringFromTimeObject(item.design.customProps.time);
      timeString = getTimeStringFromTimeObject(item.design.customProps.time);
    }
  }

  if (item.isDigital) {
    itemCategory = "Digital";
  }
  if (item.isPendant) {
    itemCategory = "Pendant";
  }

  // productName

  return {
    id: item.id, // product id
    price: itemPricing ? itemPricing.toString() : "",
    name: `${brandNameShort} - ${productName}`,
    brand: brandName,
    category: itemCategory,
    quantity: 1,
    variant,
    dimension2: textCoordinates,
    dimension3: sizeId,
    dimension4: timeString,
    dimension5: dateString,
  };
};

const getCartProducts = async (items) => {
  return Promise.all(
    items.map((item: ICartItem) =>
      getCartProduct(item, item.price && item.price.productPrice && item.price.productPrice > 0 ? item.price.productPrice / 100 : 0),
    ),
  );
};

// ///////////////////////////////////////////////////////
// When the user successfully adds the product to the cart
//
const addProductToCart = async (
  customerId: string | null,
  currency: string,
  productPrice: number | null,
  item: ICartItem,
): Promise<void> => {
  const product = await getCartProduct(item, productPrice || 0);
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "addToCart",
    ecommerce: {
      currencyCode: currency,
      add: {
        products: [product],
      },
    },
    user_id: customerId,
    page_category: "Cart",
    date_time: getGa4DateTimeString(),
  });
};

// ////////////////x///////////////////////////////////////
// When the user successfully removes a product from the cart
//
const removeFromCart = async (
  customerId: string,
  currency: string,
  items: ICartItem[],
  // orderId: string,
  // total: string,
  // shippingPrice: string,
  // coupon: string,
  // discountAmount: number,
): Promise<void> => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "removeFromCart",
    ecommerce: {
      currencyCode: currency,
      remove: {
        products: await getCartProducts(items),
      },
    },
    user_id: `${customerId}`,
    page_category: "Cart",
    date_time: getGa4DateTimeString(),
  });
};

// ///////////////////////////////////////////////////////
// When the user starts the checkout process
//
const startCheckoutStep = async (customerId: string, currency: string, items: ICartItem[], checkoutStep: any): Promise<void> => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "checkout",
    ecommerce: {
      currencyCode: currency,
      checkout: {
        actionField: checkoutStep,
        products: await getCartProducts(items),
      },
    },
    user_id: `${customerId}`,
    page_category: "Checkout",
    date_time: getGa4DateTimeString(),
  });
};

const startCheckoutStep1 = async (customerId: string, currency: string, items: ICartItem[]): Promise<void> => {
  startCheckoutStep(customerId, currency, items, { step: 1, option: "Information" });
};

const startCheckoutStep2 = async (customerId: string, currency: string, items: ICartItem[]): Promise<void> => {
  startCheckoutStep(customerId, currency, items, { step: 2, option: "Standard Shipping" });
};

const startCheckoutStep3 = async (customerId: string, currency: string, items: ICartItem[]): Promise<void> => {
  startCheckoutStep(customerId, currency, items, { step: 3, option: "Payment" });
};

// ///////////////////////////////////////////////////////
// When the user successfully purchases
//
const addPurchase = async (
  customerId: string,
  currency: string,
  items: ICartItem[],
  orderId: string,
  total: string,
  taxAmount: string,
  shippingPrice: string,
  coupon: string,
  // shippingPrice: string,
  // discountAmount: number,
): Promise<void> => {
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayerPush({
    event: "purchase",
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: orderId, // Transaction ID
          affiliation: brandNameShort,
          revenue: total,
          tax: taxAmount,
          shipping: shippingPrice,
          coupon: coupon ? coupon.toUpperCase() : null,
        },
        products: await getCartProducts(items),
      },
    },
    user_id: `${customerId}`,
    page_category: "Transaction",
    date_time: getGa4DateTimeString(),
  });
};

// const addPurchaseUpsell = async (orderId: string, _total: string, currency: string, coupon: string, item: ICartItem): Promise<void> => {

export default {
  // Page View
  logPageViewCreate,
  //
  logUserRegistration,
  // Design Page Event Defaults
  logEventDefaultTheme,
  logEventDefaultThemeOptionOnWhite,
  logEventDefaultThemeOptionConstellations,
  logEventDefaultThemeOptionGrid,
  logEventDefaultThemeOptionMoon,
  logEventDefaultDesignLayout,
  logEventDefaultDesignFormat,
  logEventDefaultDesignSize,
  //
  // Design Page Events
  logEventTheme,
  logEventThemeOptionOnWhite,
  logEventThemeOptionConstallations,
  logEventThemeOptionGrid,
  logEventThemeOptionMoon,
  logEventDesignLocation,
  logEventDesignDate,
  logEventDesignText,
  logEventDesignCoordinatesText,
  logEventDesignTime,
  logEventDesignLayout,
  logEventDesignFormat,
  logEventDesignSize,
  // Checkout Steps
  logEventDesignSaveDesign,
  logEventDesignDropAHint,
  logEventCheckoutShipping,
  logEventCheckoutPayment,
  logEventEditOrder,
  // eCommerce
  addProductToCart,
  removeFromCart,
  startCheckoutStep1,
  startCheckoutStep2,
  startCheckoutStep3,
  addPurchase,
  logEventGiftCardPurchase,
  // Gift Card
  logEventGiftCard,
  // Upsell
  upsellPendantPrintClicked,
  // Discount
  logEventCheckoutDiscount,
};
