import axios from "axios";
import env from "../config/environment";

const { TNS_PLATFORM_API, DEFAULT_PRODUCT_TYPE } = env;
const TnsSkuCache = {};

/**
 * Create a key for cache (needs to be unique to each price)
 */
const getTnsSkuCacheKey = (item: any): string | null => {
  try {
    if (!item) {
      return null;
    }

    let itemType = "print";
    if (item.isFramed === true) {
      itemType = "framed";
    } else if (item.isFramed === true) {
      itemType = "canvas";
    } else if (item.isDigital === true) {
      itemType = "digital";
    } else if (item.isUpsell || item.framingService) {
      // not sure how to deal with these - don't cache right now
      return null;
    }

    const productType = item.isPendant ? "pendant" : DEFAULT_PRODUCT_TYPE;
    const sizeId = item.design && item.design.customProps && item.design.customProps.sizeId ? item.design.customProps.sizeId : "";
    return `${productType}_${itemType}_${sizeId}`;
  } catch (error) {
    console.error(`Failed getting local sku cache key`, error);
  }

  return null;
};

/**
 * Store SKU in Cache
 */
const storeTnsSkuInCache = (item: any, storeData: any): boolean => {
  const cacheKey = getTnsSkuCacheKey(item);
  if (cacheKey === null) {
    return false;
  }

  TnsSkuCache[cacheKey] = storeData;
  return true;
};

/**
 * Get SKU from Cache
 */
const getTnsSkuFromCache = (item: any): string | null => {
  const cacheKey = getTnsSkuCacheKey(item);
  if (cacheKey === null) {
    return null;
  }

  return TnsSkuCache[cacheKey];
};

/**
 * Get SKU from API
 */
const getTnsSkuFromApi = async (item: any): Promise<string> => {
  const d = {
    customProps: item.design.customProps,
    ...(item.isFramed && { pendantMaterial: item.design.customProps.pendantMaterial }),
    isUpsell: item.isUpsell,
    isCanvas: item.isCanvas,
    isDigital: item.isDigital,
    isPendant: item.isPendant,
    ...(item.isPendant && { pendantMaterial: item.design.customProps.pendantMaterial }),
  };

  const productType = item.isPendant ? "pendant" : DEFAULT_PRODUCT_TYPE;
  const url = `${TNS_PLATFORM_API}/api/config/${productType}/sku`;
  const { data } = await axios.post(url, d);
  const { sku } = data;
  return sku;
};

/**
 * Get TNS SKU
 */
const getTnsSku = async (item: any): Promise<string> => {
  let sku = getTnsSkuFromCache(item);
  if (sku && sku !== "") {
    return sku;
  }

  sku = await getTnsSkuFromApi(item);
  if (!sku || sku === "") {
    return "";
  }

  // save in cache
  storeTnsSkuInCache(item, sku);
  return sku;
};

const getUserCountryCode = async (): Promise<string | null> => {
  try {
    const { data } = await axios.get(`${TNS_PLATFORM_API}/api/config/userCountryCode`);
    if (data && data.countryCode && data.countryCode.length === 2) {
      const { countryCode } = data;
      return countryCode;
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};

const getGiftCardPrice = async (
  countryCode: string,
  isFramed: boolean,
  isCanvas: boolean,
  isPendant: boolean,
  sizeId: string,
): Promise<any> => {
  try {
    const d = {
      countryCode,
      isFramed,
      isCanvas,
      isPendant,
      sizeId,
    };

    const { data } = await axios.post(`${TNS_PLATFORM_API}/api/config/starmap/giftCardPrice`, d);
    return data;
  } catch (err) {
    throw Error(err.response.data.message);
  }
};

const getCountryConfig = async (productTypeParam?: string, countryCode?: string): Promise<any> => {
  try {
    let query = ``;
    let separator = `?`;
    if (productTypeParam || productTypeParam === "") {
      query += `${separator}productType=${productTypeParam}`;
      separator = "&";
    }
    if (countryCode || countryCode === "") {
      query += `${separator}countryCode=${countryCode}`;
      separator = "&";
    }

    const { data } = await axios.get(`${TNS_PLATFORM_API}/api/config/getCountryConfig${query}`);
    if (data) {
      return data;
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};

export default {
  getTnsSku,
  getUserCountryCode,
  getGiftCardPrice,
  getCountryConfig,
};
