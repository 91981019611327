import { ICart } from "../interfaces";

/**
 * Returns the Cart obejct with the image data remove (to lighten it before POST to server API or when image invalidated)
 */
const getCartWithoutImages = (cart: ICart): ICart => {
  const items = cart.items.map((i) => {
    return { ...i, previewImageUrl: undefined };
  });

  return { ...cart, items };
};

export default getCartWithoutImages;
