// const activeSite = process.env.ACTIVE_SITE || `theplanetsaligned`;
const activeSite = process.env.ACTIVE_SITE !== undefined ? process.env.ACTIVE_SITE : `thenightsky`;
const port = process.env.NODE_PORT || process.env.PORT || `8080`;

// const envType = "local";
let envType = process.env.ACTIVE_ENV !== undefined ? process.env.ACTIVE_ENV : ``;
if (envType === "") {
  envType = process.env.NODE_ENV !== undefined ? process.env.NODE_ENV : `production`;
}

const usingBrowserSync = false;

// Preview appears on star map in Create page in these countries
const forceWatermark = [
  "AR",
  "BO",
  "BR",
  "BZ",
  "CL",
  "CO",
  "CR",
  "CU",
  "CW",
  "EC",
  "ES",
  "FK",
  "GD",
  "GF",
  "GP",
  "GT",
  "GY",
  "HN",
  "HT",
  "ID",
  "IN",
  "MX",
  "NI",
  "PA",
  "PE",
  "PY",
  "SR",
  "UY",
  "VE",
  "SV",
];

const generalSiteSettings = {
  thenightsky: {
    ACTIVE_SITE: "thenightsky",
    DEFAULT_PRODUCT_TYPE: "starmap",
    langPrefix: "tns",
    parentSite: `https://www.thenightsky.com`,
    productLogo: "/tns-white.png",
    cartLogo: "/tns-logo.png",
    favicon: "/tns-favicon.ico",
    brandName: "The Night Sky",
    brandNameShort: "Night Sky",
    productName: "Night Sky",
    productTypeName: "Star Map",
    copyRightNote: `© 2021, The Night Sky. The Night Sky is a registered copyright. Registration number VA 2-074-002.`,
    socialLinkFB: "https://www.facebook.com/thenightskyio",
    socialLinkInsta: "https://www.instagram.com/thenightskyio/",
    socialLinkPinterest: "https://www.pinterest.ie/thenightskyio/",
    SC_TRACKING_ID: ``,
    GA_TRACKING_ID: `UA-90411842-11`,
    PINTEREST_TRACKING_ID: `2620790907344`,
    GOOGLE_TAG_MANAGER_ID: "GTM-58RLHXH",
    APP_NAME: `Starmap`,
    PRODUCT_ID: `Starmap`,
    LOGROCKET_PROJECT_ID: `bro5fr/tns-create-page`,
    LOGROCKET_LIMIT_SESSIONS: true,
    // SENTRY_DSN: `https://27adfca0f4d34e70a7b1253b6207b1fe@o79559.ingest.sentry.io/5272217`,
    SENTRY_DSN: ``,
    SHOPIFY_CHECKOUT: false,
    SHOW_SHIPPING_LOGOS: true,
    SHOW_NEW_GIFT_PREVIEW: true,
    BING_TRACKING_ID: "25052344",
  },
  theplanetsaligned: {
    ACTIVE_SITE: "theplanetsaligned",
    DEFAULT_PRODUCT_TYPE: "orrery",
    langPrefix: "tpa",
    parentSite: `https://www.theplanetsaligned.com`,
    productLogo: "/tpa-logo-white.png",
    cartLogo: "/tpa-logo.png",
    favicon: "/tpa-favicon.ico",
    brandName: "The Planets Aligned",
    brandNameShort: "Planets Aligned",
    productName: "Planets Aligned",
    productTypeName: "Solar System",
    copyRightNote: `© 2021, The Planets Aligned`,
    socialLinkFB: "https://www.facebook.com/The-Planets-Aligned-344947706151118",
    socialLinkInsta: "https://www.instagram.com/theplanetsalignedio/",
    SC_TRACKING_ID: ``,
    GA_TRACKING_ID: `UA-90411842-5`,
    PINTEREST_TRACKING_ID: `2620790907344`,
    GOOGLE_TAG_MANAGER_ID: "GTM-NQKR4RW",
    APP_NAME: `Orrery`,
    LOGROCKET_PROJECT_ID: `bro5fr/tpa-create-page`,
    LOGROCKET_LIMIT_SESSIONS: true,
    // SENTRY_DSN: `https://e505540c912949ef99d95c2a8c6a8662@o79559.ingest.sentry.io/1765588`,
    SENTRY_DSN: ``,
    SHOPIFY_CHECKOUT: false,
    SHOW_SHIPPING_LOGOS: true,
    SHOW_NEW_GIFT_PREVIEW: false,
    BING_TRACKING_ID: "25052344",
  },
  none: {
    ACTIVE_SITE: "",
    DEFAULT_PRODUCT_TYPE: "starmap",
    langPrefix: "tns",
    parentSite: `https://`,
    productLogo: "/",
    cartLogo: "/",
    favicon: "/",
    brandName: "",
    brandNameShort: "",
    productName: "",
    productTypeName: "",
    copyRightNote: `© 2021`,
    socialLinkFB: "https://www.facebook.com/",
    socialLinkInsta: "https://www.instagram.com/",
    socialLinkPinterest: "https://www.pinterest.ie/",
    SC_TRACKING_ID: ``,
    GA_TRACKING_ID: ``,
    PINTEREST_TRACKING_ID: ``,
    GOOGLE_TAG_MANAGER_ID: "",
    APP_NAME: ``,
    PRODUCT_ID: ``,
    LOGROCKET_PROJECT_ID: ``,
    LOGROCKET_LIMIT_SESSIONS: true,
    SENTRY_DSN: ``,
    SHOPIFY_CHECKOUT: false,
    SHOW_SHIPPING_LOGOS: false,
    SHOW_NEW_GIFT_PREVIEW: false,
    BING_TRACKING_ID: "25052344",
  },
};

const siteSettings = {
  thenightsky: {
    staging: {
      ...generalSiteSettings.thenightsky,
      // overwrites the site specific setting in staging, but that's ok
      SC_TRACKING_ID: ``,
      GA_TRACKING_ID: `UA-90411842-10`,
      PINTEREST_TRACKING_ID: `2620790907344`,
      PAYPAL_CLIENT: "AfcctO2Hs5DtbjuQMlBKKSu24vB0lJHlSXdsC2r2D4_Bh7tTrohFsT6kX5rmQs5l4w4ZOuXFPoKTctBt",
      STRIPE_PUBLIC_KEY: "pk_test_51AgWXnGKdvUKlbbqbcbaavwelJ52pddc2i8VsHNGjLoMlmFmbLJYsvF7aWlbaNl347BNxchepMcD9EgcD1hP1Yji00QhjWKdlc",
      STRIPE_PUBLIC_KEY_USDACC:
        "pk_test_519zDf6BguTso9FfE6fJuALgsCbm7cUYlXVxmUpNFOgIOirWVwctQi3BvZ7CTc3wU9R76gb0hx9a5SY5sN0YQWwqs0059cNtj9q",
      STRIPE_ACCOUNT_COUNTRY: "IE",
      TNS_PLATFORM_API: `https://tns-api-staging.thenightsky.com`,
      TNS_PLATFORM_PAYMENTS_API: `https://tns-api-staging.thenightsky.com`,
      WEBSITE_URL: `https://staging.thenightsky.com`,
      STARMAP_URL: `https://create-staging.thenightsky.com`,
      SUPPORT_URL: `https://support-staging.thenightsky.com`,
      ADMIN_URL: `https://admin-staging.thenightsky.com`,
      TNS_RENDER_PREVIEW_SERVER: "https://render-staging.thenightsky.com",
      TPA_RENDER_PREVIEW_SERVER: "https://render-staging.thenightsky.com",
      FBP_TRACKING_ID: `406491799699930`,
      ACTIVE_ENV: envType,
      PORT: port,
      STAMPED_API_KEY: `pubkey-59439qpdG7599oXA9s2dDjZv8qVAr1`,
      STAMPED_STORE_URL: `timeandplace.myshopify.com`,
      BLACKLISTED_COUNTRIES: "AR,BR,ID,IN,MX", // redirects to digital
      GOOGLE_MAPS_API_KEY: "AIzaSyAS7sZdHfkDd1KQMUV_D7E760Fefg_s1Vc",
      FORCE_PREVIEW_WATERMARK: forceWatermark,
      AMAZONPAY_ENABLED: true,
      AMAZONPAY_MERCHANT_ID: "A3QZSUX2R8AI4L",
      AMAZONPAY_LEDGER_CURRENCY: "USD",
      AMAZONPAY_PRESENTMENT_CURRENCIES: "USD,",
      AMAZONPAY_SANDBOX: true,
      DIGITAL_OPTION_ENABLED: false,
    },
    production: {
      ...generalSiteSettings.thenightsky,
      PAYPAL_CLIENT: "AR6LLtJXYq-nqwAdzVyaUY0jWo1ojamDwvxDVpov5qH1pmn-QG3xOeVGCRluG6uJJrmdy7-8cnH3NLld",
      STRIPE_PUBLIC_KEY: "pk_live_51AgWXnGKdvUKlbbqPqeNi8lFeTrdhnBCjotnuL6xx8vICMLoVlqdVuMSKj0ZEeWEIiiUAQK7EFpsrIHCtPhqoROq005Ct4BV59",
      STRIPE_PUBLIC_KEY_USDACC:
        "pk_live_519zDf6BguTso9FfElsk0Uq1pW24x6xC7r9PUUEGhTojZSyFLru7yHsIkqwvyjWsiLHSNIsMqqbjTtH64CEpDy9Tk00tMBQj6ae",
      STRIPE_ACCOUNT_COUNTRY: "IE",
      STAMPED_API_KEY: "pubkey-59439qpdG7599oXA9s2dDjZv8qVAr1",
      TNS_PLATFORM_API: `https://api-server.thenightsky.com`,
      TNS_PLATFORM_PAYMENTS_API: `https://api-payments.thenightsky.com`,
      WEBSITE_URL: `https://thenightsky.com`,
      STARMAP_URL: `https://create.thenightsky.com`,
      ADMIN_URL: `https://tns-admin.com`,
      SUPPORT_URL: `https://tns-support.com`,
      TNS_RENDER_PREVIEW_SERVER: "https://render-preview.thenightsky.com",
      TPA_RENDER_PREVIEW_SERVER: "https://render-preview.thenightsky.com",
      FBP_TRACKING_ID: `406491799699930`,
      ACTIVE_ENV: envType,
      PORT: port,
      BLACKLISTED_COUNTRIES: "AR,BR,ID,IN,MX", // redirects to digital
      GOOGLE_MAPS_API_KEY: "AIzaSyBJ0fTlIK_ii5SQemJjVX4FWiBX-lyGkXA",
      FORCE_PREVIEW_WATERMARK: forceWatermark,
      LOGROCKET_PROJECT_ID: `bro5fr/tns-platform-starmap`,
      AMAZONPAY_ENABLED: true,
      AMAZONPAY_MERCHANT_ID: "A3QZSUX2R8AI4L",
      AMAZONPAY_LEDGER_CURRENCY: "USD",
      AMAZONPAY_PRESENTMENT_CURRENCIES: "USD,",
      AMAZONPAY_SANDBOX: false,
      DIGITAL_OPTION_ENABLED: true,
    },
  },
  theplanetsaligned: {
    staging: {
      ...generalSiteSettings.theplanetsaligned,
      // overwrites the site specific setting in staging, but that's ok
      SC_TRACKING_ID: ``,
      GA_TRACKING_ID: `UA-90411842-10`,
      PINTEREST_TRACKING_ID: `2620790907344`,
      PAYPAL_CLIENT: "AfcctO2Hs5DtbjuQMlBKKSu24vB0lJHlSXdsC2r2D4_Bh7tTrohFsT6kX5rmQs5l4w4ZOuXFPoKTctBt",
      STRIPE_PUBLIC_KEY: "pk_test_51AgWXnGKdvUKlbbqbcbaavwelJ52pddc2i8VsHNGjLoMlmFmbLJYsvF7aWlbaNl347BNxchepMcD9EgcD1hP1Yji00QhjWKdlc",
      STRIPE_PUBLIC_KEY_USDACC:
        "pk_test_519zDf6BguTso9FfE6fJuALgsCbm7cUYlXVxmUpNFOgIOirWVwctQi3BvZ7CTc3wU9R76gb0hx9a5SY5sN0YQWwqs0059cNtj9q",
      STRIPE_ACCOUNT_COUNTRY: "IE",
      TNS_PLATFORM_API: `https://tns-api-staging.thenightsky.com`,
      TNS_PLATFORM_PAYMENTS_API: `https://tns-api-staging.thenightsky.com`,
      WEBSITE_URL: `https://create-staging.thenightsky.com`,
      STARMAP_URL: `https://create-staging.thenightsky.com`,
      ADMIN_URL: `https://admin-staging.thenightsky.com`,
      SUPPORT_URL: `https://support-staging.thenightsky.com`,
      TNS_RENDER_PREVIEW_SERVER: "https://render-preview.theplanetsaligned.com",
      TPA_RENDER_PREVIEW_SERVER: "https://render-preview.theplanetsaligned.com",
      FBP_TRACKING_ID: `406491799699930`,
      ACTIVE_ENV: envType,
      PORT: port,
      STAMPED_API_KEY: `pubkey-59439qpdG7599oXA9s2dDjZv8qVAr1`,
      STAMPED_STORE_URL: `timeandplace.myshopify.com`,
      BLACKLISTED_COUNTRIES: "AR,BR,ID,IN,MX", // redirects to digital
      GOOGLE_MAPS_API_KEY: "AIzaSyAS7sZdHfkDd1KQMUV_D7E760Fefg_s1Vc",
      FORCE_PREVIEW_WATERMARK: forceWatermark,
      AMAZONPAY_ENABLED: true,
      AMAZONPAY_MERCHANT_ID: "A3QZSUX2R8AI4L",
      AMAZONPAY_LEDGER_CURRENCY: "USD",
      AMAZONPAY_PRESENTMENT_CURRENCIES: "USD,",
      AMAZONPAY_SANDBOX: true,
      DIGITAL_OPTION_ENABLED: false,
    },
    production: {
      ...generalSiteSettings.theplanetsaligned,
      PAYPAL_CLIENT: "AR6LLtJXYq-nqwAdzVyaUY0jWo1ojamDwvxDVpov5qH1pmn-QG3xOeVGCRluG6uJJrmdy7-8cnH3NLld",
      STRIPE_PUBLIC_KEY: "pk_live_51AgWXnGKdvUKlbbqPqeNi8lFeTrdhnBCjotnuL6xx8vICMLoVlqdVuMSKj0ZEeWEIiiUAQK7EFpsrIHCtPhqoROq005Ct4BV59",
      STRIPE_PUBLIC_KEY_USDACC:
        "pk_live_519zDf6BguTso9FfElsk0Uq1pW24x6xC7r9PUUEGhTojZSyFLru7yHsIkqwvyjWsiLHSNIsMqqbjTtH64CEpDy9Tk00tMBQj6ae",
      STRIPE_ACCOUNT_COUNTRY: "IE",
      STAMPED_API_KEY: "pubkey-59439qpdG7599oXA9s2dDjZv8qVAr1",
      TNS_PLATFORM_API: `https://api-server.thenightsky.com`,
      TNS_PLATFORM_PAYMENTS_API: `https://api-payments.thenightsky.com`,
      WEBSITE_URL: `https://create.thenightsky.com`,
      STARMAP_URL: `https://create.thenightsky.com`,
      ADMIN_URL: `https://tns-admin.com`,
      SUPPORT_URL: `https://tns-support.com`,
      TNS_RENDER_PREVIEW_SERVER: "https://render-preview.theplanetsaligned.com",
      TPA_RENDER_PREVIEW_SERVER: "https://render-preview.theplanetsaligned.com",
      FBP_TRACKING_ID: `406491799699930`,
      ACTIVE_ENV: envType,
      PORT: port,
      BLACKLISTED_COUNTRIES: "AR,BR,ID,IN,MX", // redirects to digital
      GOOGLE_MAPS_API_KEY: "AIzaSyBJ0fTlIK_ii5SQemJjVX4FWiBX-lyGkXA",
      FORCE_PREVIEW_WATERMARK: forceWatermark,
      LOGROCKET_PROJECT_ID: `bro5fr/tns-platform-starmap`,
      AMAZONPAY_ENABLED: true,
      AMAZONPAY_MERCHANT_ID: "A3QZSUX2R8AI4L",
      AMAZONPAY_LEDGER_CURRENCY: "USD",
      AMAZONPAY_PRESENTMENT_CURRENCIES: "USD,",
      AMAZONPAY_SANDBOX: false,
      DIGITAL_OPTION_ENABLED: true,
    },
  },
  none: {
    staging: {
      ...generalSiteSettings.none,
      // overwrites the site specific setting in staging, but that's ok
      SC_TRACKING_ID: ``,
      GA_TRACKING_ID: ``,
      PINTEREST_TRACKING_ID: ``,
      PAYPAL_CLIENT: "",
      STRIPE_PUBLIC_KEY: "",
      STRIPE_PUBLIC_KEY_USDACC: "",
      STRIPE_ACCOUNT_COUNTRY: "",
      WEBSITE_URL: `https://`,
      STARMAP_URL: `https://`,
      TNS_PLATFORM_API: `https://`,
      TNS_PLATFORM_PAYMENTS_API: `https://`,
      SUPPORT_URL: `https://`,
      FBP_TRACKING_ID: ``,
      ACTIVE_ENV: envType,
      PORT: port,
      STAMPED_API_KEY: ``,
      STAMPED_STORE_URL: ``,
      ADMIN_URL: `https://`,
      BLACKLISTED_COUNTRIES: "", // redirects to digital
      GOOGLE_MAPS_API_KEY: "",
      FORCE_PREVIEW_WATERMARK: forceWatermark,
      TPA_RENDER_PREVIEW_SERVER: "",
      AMAZONPAY_ENABLED: false,
      AMAZONPAY_MERCHANT_ID: "",
      AMAZONPAY_LEDGER_CURRENCY: "USD",
      AMAZONPAY_PRESENTMENT_CURRENCIES: "USD,",
      AMAZONPAY_SANDBOX: true,
      DIGITAL_OPTION_ENABLED: false,
    },
    production: {
      ...generalSiteSettings.none,
      GA_TRACKING_ID: ``,
      PINTEREST_TRACKING_ID: ``,
      PAYPAL_CLIENT: "",
      STRIPE_PUBLIC_KEY: "",
      STRIPE_PUBLIC_KEY_USDACC: "",
      STRIPE_ACCOUNT_COUNTRY: "",
      WEBSITE_URL: `https://`,
      STARMAP_URL: `https://`,
      TNS_PLATFORM_API: `https://`,
      TNS_PLATFORM_PAYMENTS_API: `https://`,
      SUPPORT_URL: `https://`,
      FBP_TRACKING_ID: ``,
      ACTIVE_ENV: envType,
      PORT: port,
      STAMPED_API_KEY: ``,
      STAMPED_STORE_URL: ``,
      ADMIN_URL: `https://`,
      BLACKLISTED_COUNTRIES: "", // redirects to digital
      GOOGLE_MAPS_API_KEY: "",
      FORCE_PREVIEW_WATERMARK: forceWatermark,
      TPA_RENDER_PREVIEW_SERVER: "",
      AMAZONPAY_ENABLED: false,
      AMAZONPAY_MERCHANT_ID: "",
      AMAZONPAY_LEDGER_CURRENCY: "USD",
      AMAZONPAY_PRESENTMENT_CURRENCIES: "USD,",
      AMAZONPAY_SANDBOX: true,
      DIGITAL_OPTION_ENABLED: false,
    },
  },
};

const local = {
  ...siteSettings[activeSite].staging,
  // WEBSITE_URL: `/`,
  TNS_PLATFORM_API: usingBrowserSync === true ? `https://tns-api-staging.thenightsky.com` : `http://localhost:5000`,
  TNS_PLATFORM_PAYMENTS_API: usingBrowserSync === true ? `https://tns-api-staging.thenightsky.com` : `http://localhost:5000`,
  // SUPPORT_URL: `http://localhost:3002`,
  // ACTIVE_ENV: envType,
  // PORT: 3004,
  // SENTRY_DSN: ``,
  // ADMIN_URL: `http://localhost:3003`,
  // GOOGLE_TAG_MANAGER_ID: "GTM-58RLHXH",
};

const configs = {
  local,
  development: local,
  staging: siteSettings[activeSite].staging,
  production: siteSettings[activeSite].production,
};

const config = configs[envType];

// console.log(`STARTUP CONFIG: [envType: ${envType}] `, config);
module.exports = { ...config };
